<template>
  <div class="service-view">
    <div class="sctp-mar-b15 sctp-container">
      <div class="sctp-pad-tb10">
        <el-breadcrumb separator=">">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>技术服务大厅</el-breadcrumb-item>
          <el-breadcrumb-item>技术服务详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <template v-if="notExist === true">
        <div style="padding: 50px 0;" class="sctp-bg-white">
          <empty :message="'技术服务不存在或已下架'"></empty>
        </div>
      </template>
      <template v-else>
        <div class="goods-header pad15 sctp-bg-white">
          <el-row type="flex" :gutter="10">
            <el-col class="flex flex-col" :span="7">
              <div class="sctp-bg-white flex-item1 ">
                <template
                  v-if="service && service.servicecover && service.servicecover.length > 0">
                  <show-picture
                    :images="service.servicecovers"></show-picture>
                </template>
                <div class="pad-t10 flex flex-sb">
                  <div>
                    <template
                      v-if="!(service && user && service.userid === user.userId)"
                    >
                      <a v-if="service && service.favorite"
                         class="sctp-color-main"
                         @click="doPrivileged(favoriteConfig.onClick)">
                        <i class="el-icon-star-on"></i>
                        已收藏
                      </a>
                      <a v-else
                         @click="doPrivileged(favoriteConfig.onClick)">
                        <i class="el-icon-star-off"></i>
                        收藏
                      </a>
                    </template>
                  </div>
                  <a @click="reportConfig.showReport">
                    <i class="el-icon-warning"></i>
                    举报
                  </a>
                </div>
                <div class="view-control-wrap">
                  <div class="sctp-tc">
                    <div class="sctp-mar-b5">浏览数</div>
                    <div class="number">{{ service && service.hits || 0 }}</div>
                  </div>
                  <div class="sctp-tc">
                    <div class="sctp-mar-b5">沟通数</div>
                    <div class="number">{{ service && service.views || 0 }}</div>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col class="flex flex-col" :span="12">
              <div class="sctp-bg-white flex-item1 pad15">
                <div class="sctp-font-24 productName">{{
                    service && service.servicename || ' '
                  }}
                </div>
                <div
                  class="sctp-mar-tb15 sctp-bg-f8 sctp-pad-tb15 sctp-pad-lr20 sctp-info sctp-font-14 sctp-flex">
                  <div class="sctp-flex-item-1">
                    <span>价格</span>
                    <span class="sctp-red sctp-font-28 sctp-mar-l5">¥{{
                        service && service.serviceprice || 0
                      }}起/{{service&&service.unit}}</span>
                  </div>
                </div>
                <template v-if="service">
                  <div class="sctp-flex sctp-pad-tb5">
                    <div>服务编号：</div>
                    <div>#{{ service.serviceid }}</div>
                  </div>
                  <div class="sctp-pad-tb5">服务类目：
                    <template v-if="service">
                      {{ service.oneType }} ->
                      {{ service.twoType }}
                    </template>
                  </div>
                </template>
                <div class="mg-t10">
                  <el-button
                    @click="doPrivileged(doBuyService)" type="success"
                  >
                    立即购买
                  </el-button>
                </div>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="sctp-pad15 sctp-bg-white" style="border: 1px solid #eee;">
                <div class="sctp-flex sctp-pad-b15" style="border-bottom: 1px solid #eee;">
                  <el-avatar class="sctp-mar-r10"
                             :src="shopInfo && shopInfo.logo"></el-avatar>
                  <div class="sctp-flex-item-1 sctp-flex sctp-flex-sb sctp-flex-column">
                    <div>{{ shopInfo && shopInfo.shopname }}的小店</div>
                    <div class="sctp-flex sctp-font-12">
                      <div class="sctp-mar-r5"><span><img class="auth_ok_icon"
                                                          src="/images/icon/shop_ok.png"/></span><span>店铺认证</span>
                      </div>
                      <div><span><img class="auth_ok_icon"
                                      src="/images/icon/user_ok.png"/></span><span>实名认证</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="sctp-info sctp-font-14 sctp-pad-tb10 shop-info">
                  <div class="sctp-flex">
                    <div class="title">店家：</div>
                    <div class="value">{{ shopInfo && shopInfo.shopname }}</div>
                  </div>
                  <div class="sctp-flex">
                    <div class="title">宝贝：</div>
                    <div class="value">{{ shopInfo && shopInfo.goodsCount }}件</div>
                  </div>
                  <div class="sctp-flex">
                    <div class="title">地址：</div>
                    <div class="value">{{ shopAddress }}</div>
                  </div>
                  <div class="sctp-flex">
                    <div class="title">创店：</div>
                    <div class="value">{{
                        shopInfo && shopInfo.createtime | dateString('yyyy-MM-dd')
                      }}
                    </div>
                  </div>
                  <div class="sctp-flex">
                    <div class="title">联系：</div>
                    <div class="value"><a class="sctp-blue"
                                          @click="doPrivileged(viewPhoneDialogConfig.onViewClick)">查看联系方式</a>
                    </div>
                  </div>
                </div>
                <div>
                  <router-link :to="'/service-provider/view/' + (shopInfo && shopInfo.shopid || '')">
                    <el-button icon="el-icon-s-shop" size="mini">进入店铺</el-button>
                  </router-link>

                  <template
                    v-if="!(shopInfo && user && shopInfo.userid === user.userId)"
                  >
                    <el-button v-if="shopInfo && shopInfo.favorite"
                               @click="doPrivileged(favoriteShop)"
                               size="mini" icon="el-icon-star-on" type="primary"
                               :loading="!collectionBtnCanClickFlag"
                               class="mg-l5"
                    >
                      已收藏
                    </el-button>
                    <el-button v-else
                               :loading="!collectionBtnCanClickFlag"
                               @click="doPrivileged(favoriteShop)" size="mini"
                               class="mg-l5" icon="el-icon-star-off"
                    >收藏店铺
                    </el-button>
                  </template>
                </div>
                <div class="mg-t10" v-if="shopInfo && shopInfo.deposit">
                  <el-alert
                    class="deposit-alter"
                    center
                    type="success"
                    :closable="false">
                    <div slot="title">
                      商家已缴纳保证金<span class="sctp-color-main fz-16">{{
                        shopInfo.deposit.toMoney()
                      }}</span>元
                    </div>
                  </el-alert>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <el-row :gutter="15" class="sctp-mar-t15">
          <el-col :span="18">
            <div class="goods-body sctp-bg-white">
              <el-tabs value="first" class="body-tabs" type="border-card">
                <el-tab-pane label="服务详情" name="first">
                  <div class="">
                    <div class="sctp-pad-tb5">服务类目：
                      <template v-if="service">
                        {{ service.oneType }} ->
                        {{ service.twoType }}
                      </template>
                    </div>
                    <div class="sctp-pad-tb5 sctp-flex">
                      <div>
                        服务属性：
                      </div>
                      <div class="sctp-flex-item-1">
                        <template v-if="service && service.attributes">
                          <template v-for="service in service.attributes">
                            <div class="sctp-flex">
                              <div style="line-height: 2;">{{ service.name }}：</div>
                              <div class="sctp-flex-item-1">
                                <template v-for="attribute in service.children">
                                  <span style="display: inline-block;"
                                        class="sctp-pad-lr5 sctp-pad-tb5 sctp-mar-b5"
                                        :key="attribute.name">
                                    <el-tag size="mini">{{ attribute.name }}</el-tag>
                                  </span>
                                </template>
                              </div>
                            </div>
                          </template>
                        </template>
                      </div>
                    </div>
                    <div class="sctp-flex sctp-pad-tb5">
                      <div>服务附件：</div>
                      <div>
                        <template v-for="item in service.serviceannexs">
                          <div>
                            <a target="_blank" :href="item">
                              <i class="el-icon-document mg-r5"></i>
                              {{
                                item.substring(item.lastIndexOf('/') + 1)
                              }}
                            </a>
                          </div>
                        </template>
                      </div>
                    </div>
                    <div class="sctp-pad-tb10">
                      <hr/>
                    </div>
                    <div class="rich-text" v-html="service && service.servicedetails || ''"></div>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="服务评价" name="two">
                  <div class="">
                    <el-divider><span class="sctp-font-24">服务评价</span></el-divider>
                    <comment :id="Number(id)"></comment>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="交易流程" name="three">
                  <div class="">
                    <el-divider><span class="sctp-font-24">交易流程</span></el-divider>
                    <div class="rich-text" v-html="transactionProcessConfig.content"></div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </el-col>
          <el-col :span="6">
            <template v-if="recommendServiceConfig.data && recommendServiceConfig.data.length > 0">
              <div class="sctp-bg-white">
                <div class="sctp-flex sctp-flex-sb sctp-pad10">
                  <div>相似推荐</div>
                  <div></div>
                </div>
                <template v-for="(recommendItem,index) in recommendServiceConfig.data">
                  <a target="_blank" :href="'/service/view/' + recommendItem.serviceid"
                     :key="recommendItem.serviceid">
                    <div class="">
                      <div class="sctp-flex sctp-wfull sctp-pad5">
                        <el-image
                          class="sctp-mar-r10"
                          :src="recommendItem.servicecovers[0]"
                          fit="cover"
                          style="width:100px;height:100px;"
                        ></el-image>
                        <div class="sctp-flex-item-1">
                          <div class="flex flex-sb flex-col full">
                            <div class="sctp-ellipsis--l3">{{ recommendItem.servicename }}</div>
                            <div class="sctp-mar-t5"><span>价格：</span><span class="sctp-red">¥{{
                                recommendItem.serviceprice
                              }}</span></div>
                          </div>
                        </div>
                      </div>
                      <template v-if="index !== recommendServiceConfig.data.length - 1">
                        <div class="divider"></div>
                      </template>
                    </div>
                  </a>
                </template>
              </div>
            </template>
          </el-col>
        </el-row>
      </template>
    </div>
    <el-dialog
      center
      title="查看联系方式"
      width="600px"
      :visible.sync="viewPhoneDialogConfig.showPayDialog"
    >
      <pay :pay-amount="shopInfo && shopInfo.viewContactPrice || 0"
           @pay="viewPhoneDialogConfig.onPay"
           :can-use-reward="true"
      ></pay>
    </el-dialog>
    <el-dialog
      center
      title="联系方式"
      width="500px"
      :visible.sync="viewPhoneDialogConfig.show"
    >
      <div>
        <div class="flex pad-tb5">
          <div class="sctp-tr" style="width: 100px;">手机号码：</div>
          <div>{{ shopInfo && shopInfo.phone || '未设置' }}</div>
        </div>
        <div class="flex pad-tb5">
          <div class="sctp-tr" style="width: 100px;">Email邮箱：</div>
          <div>{{ shopInfo && shopInfo.email || '未设置' }}</div>
        </div>
        <div class="flex pad-tb5">
          <div class="sctp-tr" style="width: 100px;">QQ号：</div>
          <div>{{ shopInfo && shopInfo.qq || '未设置' }}</div>
        </div>
        <div class="flex pad-tb5">
          <div class="sctp-tr" style="width: 100px;">微信号：</div>
          <div>{{ shopInfo && shopInfo.wechat || '未设置' }}</div>
        </div>
        <div class="flex pad-tb5">
          <div class="sctp-tr" style="width: 100px;">微信二维码：</div>
          <div>
            <template v-if="shopInfo && shopInfo.wechat_qrcode">
              <el-image
                :src="shopInfo.wechat_qrcode"
                style="width: 80px;height: 80px;"
                fit="contain"
              ></el-image>
            </template>
            <template v-else>
              未设置
            </template>
          </div>
        </div>
        <div class="fz-12 fc-info">
          注：以上由卖家自行填写，请及时联系，联系时请说明信息来源。
        </div>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="服务信息内容举报"
      :visible.sync="reportConfig.show"
    >
      <report
        @success="reportConfig.hiddenReport"
        :target="{
             title: service && service.servicename || ' ',
             id: service && service.serviceid || null
          }"
        :type="2"></report>
    </el-dialog>
    <el-dialog
      title="平台服务条款"
      :visible.sync="centerDialogVisible"
      class="register-dialog"
      center>
      <div class="rich-text" v-html="productReleaseTerms"></div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="查看联系方式需支付服务费"
      :visible.sync="openPayAfterFlag"
      width="26%"
      class="register-dialog"
      center>
      <div>
        <div style="text-align: center;color: red;font-size: 20px">{{shopInfo.viewContactPrice.toMoney()}}元</div>
        <div style="text-align: left;">确认支付代表知晓并同意本次服务费及平台的免责条款，以下联系方式仅打勾的有效</div>
        <div class="flex flex-center">
          <div class="sctp-mar-r10"><i class="el-icon-success sctp-color-main" v-if="shopInfo && shopInfo.hasPhone"></i>
            <i class="el-icon-error" v-else></i>手机号</div>
          <div class="sctp-mar-r10"><i class="el-icon-success sctp-color-main" v-if="shopInfo && shopInfo.hasEmail"></i>
            <i class="el-icon-error" v-else></i>邮箱</div>
          <div class="sctp-mar-r10">
            <i class="el-icon-success sctp-color-main" v-if="shopInfo && shopInfo.hasQQ"></i>
            <i class="el-icon-error" v-else></i>QQ</div>
          <div class="sctp-mar-r10">
            <i class="el-icon-success sctp-color-main" v-if="shopInfo && shopInfo.hasWechat"></i>
            <i class="el-icon-error" v-else></i>微信</div>
        </div>
      </div>
      <div class="sctp-flex sctp-flex-center" style="padding-top: 10px">
        <el-checkbox v-model="read">
          我已阅读
          <a @click.prevent="centerDialogVisible = true" class="sctp-color-main">《平台服务条款》</a>
          并同意
        </el-checkbox>
      </div>
      <div slot="footer" class="dialog-footer">

        <el-button @click="openPayAfterFlag = false" >取消</el-button>
        <el-button type="primary" @click="openPay()">支付</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {collection, common, service, shop} from "../../../apis";
import area from "@/resources/js/area";

const config = require('../../../resources/js/config');
export default {
  name: 'productView',
  components: {
    pay: () => import('@CMP/common/BuyPay'),
    empty: () => import('@CMP/common/EmptyData.vue'),
    report: () => import('@CMP/common/UserReport'),
    Comment: () => import('@PAGE/shop-manage/service-manage/Comment'),
    ShowPicture: () => import('@CMP/product/ShowPicture'),
  },
  data() {
    return {
      notExist: null,
      service: null,
      collectionBtnCanClickFlag: true,
      shopInfo: null,
      openPayAfterFlag:false,
      productReleaseTerms:null,
      read:false,
      centerDialogVisible:false,
      viewPhoneDialogConfig: {
        show: false,
        showPayDialog: false,
        onViewClick: () => {
          if (!this.shopInfo) {
            return;
          }
          if (this.shopInfo.showContact === true) {
            this.viewPhoneDialogConfig.show = true;
          } else {
            common.contactClickHistory({
              userId: this.user.userId,
              type: 5,
              id: this.id,
            })
            this.viewPhoneDialogConfig.confirmPay();
          }
        },
        confirmPay: () => {

          if (this.shopInfo.viewContactPrice == 0) {
            this.viewPhoneDialogConfig.show = true;
            return;
          }

          if (!this.shopInfo ) {
            return;
          }
          this.openPayAfterFlag = true
          // let {shopInfo} = this;
          // this.$confirm(`<div>
          //     <div class="sctp-red sctp-font-18">${shopInfo.viewContactPrice.toMoney()}元</div>
          //     <div style="text-align: left;">确认支付代表知晓并同意本次服务费及平台的免责条款，以下联系方式仅打勾的有效</div>
          //     <div class="flex flex-center">
          //     <div class="sctp-mar-r10">${shopInfo && shopInfo.hasPhone
          //   ? '<i class="el-icon-success sctp-color-main"></i>' : '<i class="el-icon-error"></i>'}手机号</div>
          //     <div class="sctp-mar-r10">${shopInfo && shopInfo.hasEmail
          //   ? '<i class="el-icon-success sctp-color-main"></i>' : '<i class="el-icon-error"></i>'}邮箱</div>
          //     <div class="sctp-mar-r10">${shopInfo && shopInfo.hasQQ
          //   ? '<i class="el-icon-success sctp-color-main"></i>' : '<i class="el-icon-error"></i>'}QQ</div>
          //     <div class="sctp-mar-r10">${shopInfo && shopInfo.hasWechat
          //   ? '<i class="el-icon-success sctp-color-main"></i>' : '<i class="el-icon-error"></i>'}微信</div>
          //     </div>
          //     </div>`, {
          //   dangerouslyUseHTMLString: true,
          //   confirmButtonText: '支付',
          //   cancelButtonText: '取消',
          //   center: true,
          //   title: '查看联系方式需支付服务费',
          // }).then(() => {
          //   this.viewPhoneDialogConfig.showPayDialog = true;
          // })
        },
        onPay: ({payWay}) => {
          shop.viewContactInformation({
            userId: this.user.userId,
            shopId: this.shopInfo.shopid,
            payWay: payWay,
            eType: 5,
            eId: this.id
          }).then(() => {
            this.viewPhoneDialogConfig.showPayDialog = false;
            this.getServiceDetail().then(() => {
              this.viewPhoneDialogConfig.show = true;
            });
          })
        },
      },
      reportConfig: {
        show: false,
        showReport: () => {
          this.reportConfig.show = true
        },
        hiddenReport: () => {
          this.reportConfig.show = false
        }
      },
      favoriteConfig: {
        onClick: () => {
          if (!this.favoriteConfig.canClick) {
            return;
          }
          this.favoriteConfig.canClick = false;
          collection.addFavorite({
            userId: (this.user && this.user.userId) || null,
            targetId: this.id,
            targetType: 5
          }).then(res => {
            const {data} = res;
            this.service.favorite = data;
            if (data) {
              this.$message.success('收藏成功')
            } else {
              this.$message.success('取消收藏成功')
            }
          }).finally(() => {
            this.favoriteConfig.canClick = true;
          })
        },
        canClick: true,
      },
      recommendServiceConfig: {
        data: [],
        getRecommendServices: () => {
          service.similarRecommendation({
            serviceId: this.id
          }).then(res => {
            const {data} = res;
            this.recommendServiceConfig.data = data;
          })
        },
      },
      transactionProcessConfig: {
        content: '',
        loadData: () => {
          common.loadProtocol({
            protocolName: '服务交易流程'
          }).then(res => {
            let {data} = res;
            this.transactionProcessConfig.content = data;
          })
        }
      },
    }
  },
  props: ['id'],
  methods: {
    doBuyService() {
      this.goPage({
        path: '/service/buy/confirmOrder',
        name: '服务确认订单',
        query: {
          serviceId: this.id
        }
      })
    },
    getServiceDetail() {
      if (!this.id) {
        return Promise.reject();
      }
      return this.$request.post({
        reqcode: '1122',
        reqdesc: '服务详情',
        serviceId: this.id,
        userId: (this.user && this.user.userId) || null
      }).then(res => {
        const {data, shopInfo} = res;
        // 保存浏览历史记录
        common.viewHistory({
          userId: (this.user && this.user.userId) || null,
          type: 4,
          id: this.id,
          title: data.servicename
        });
        data.servicecover = data.servicecover ? data.servicecover.split(',') : '';
        this.service = data;
        this.shopInfo = shopInfo
      })
      .catch(err => {
        if (err.retCode === '9000') {
          this.notExist = true;
        }
        return Promise.reject(err);
      })
    },
    /**
     * 收藏店铺
     */
    favoriteShop() {
      if (!this.shopInfo) {
        return;
      }
      this.collectionBtnCanClickFlag = false;
      collection.addFavorite({
        userId: (this.user && this.user.userId) || null,
        targetType: 1,
        targetId: this.shopInfo.shopid
      }).then(res => {
        const {data} = res;
        this.shopInfo.favorite = data;
        if (data) {
          this.$message.success('收藏成功')
        } else {
          this.$message.success('取消收藏成功')
        }
      }).finally(() => {
        this.collectionBtnCanClickFlag = true
      })
    },
    getProductReleaseTerms() {
      common.loadProtocol({
        protocolName: '平台服务条款'
      }).then(res => {
        const {data} = res;
        this.productReleaseTerms = data
      })
    },
    openPay(){
      if (!this.read){
        this.$message.warning('请确认阅读条款');
        return
      }
      this.openPayAfterFlag =false
      this.viewPhoneDialogConfig.showPayDialog =true
    }
  },
  computed: {
    shopAddress() {
      let ret = '';
      if (this.shopInfo) {
        let province = area.parseProvince(this.shopInfo.provinceCode);
        let city = area.parseCity(this.shopInfo.cityCode);
        ret = `${province.name}-${city.name}`;
      }
      return ret;
    },
  },
  beforeMount() {
    this.getServiceDetail();
    this.recommendServiceConfig.getRecommendServices();
    this.transactionProcessConfig.loadData();
    this.getProductReleaseTerms()

  }
}
</script>

<style scoped lang="scss">
.service-view /deep/ .el-dialog__body {
  overflow-y: auto;
}
//.el-dialog__wrapper{
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  margin-bottom: 120px;
//}
.view-control-wrap {
  margin-top: 15px;
  display: flex;
  //border-style: solid;
  //border-width: 1px 0 1px 0;
  //border-color: #eee;

  & > div {
    flex: 1;
    padding: 8px 0;
    border-width: 1px;
    border-style: solid;
    border-color: #eee;

    .number {
      font-size: 20px;
      font-weight: bold;
    }
  }

  & > div:last-child {
    border-left: unset;

    .number {
      color: #F90;
    }
  }
}

.goods-header .el-carousel {
  width: 100%;
}

.goods-header .el-input-number {
  width: 150px;
  margin-right: 10px;
}

.goods-header .el-avatar {
  height: 50px;
  width: 50px;
}

.goods-header .shop-info div {
  line-height: 28px;
}

.goods-header .shop-info .title {
  display: inline-block;
  margin-right: 5px;
}

.goods-header .shop-info .value {
  flex: 1;
}

.goods-score div {
  position: relative;
}

.goods-score div:not(:last-child):after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  top: 10px;
  bottom: 10px;
  background-color: red;
  width: 1px;
}

.goods-score div span:nth-child(1) {
  color: #F90;
  font-weight: bold;
  margin-bottom: 10px;
}

.evaluate-item + .evaluate-item {
  margin-top: 10px;
}

.evaluate-item /deep/ .el-rate__icon {
  margin-right: 0;
}

.el-divider--horizontal {
  width: 50%;
  transform: translateX(50%)
}

.tips-info {
  padding: 15px;
  line-height: 1.2;
}

.auth_ok_icon {
  height: 12px;
  width: 12px;
  object-fit: cover;
  vertical-align: middle;
  margin-right: 3px;
}

.rich-text {
  & /deep/ img {
    max-width: 100%;
  }
}

.divider {
  height: 1px;
  border-bottom: #dddddd 1px dashed;
  margin-bottom: 8px;
  margin-top: 8px;
  width: 100%;
}
</style>
